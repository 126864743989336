import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "MotionEye shows a strange video image after some running time",
  "path": "/Frequently_Asked_Question/MotionEye_Keyframe_Drops_over_UDP/",
  "dateChanged": "2020-01-21",
  "author": "Mike Polinowski",
  "excerpt": "I am wondering which of the 5905 or 5907 cameras I should buy?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='MotionEye shows a strange video image after some running time' dateChanged='2021-01-19' author='Mike Polinowski' tag='INSTAR IP Camera' description='I am wondering which of the 5905 or 5907 cameras I should buy?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/' locationFR='/fr/Frequently_Asked_Question/MQTT_Alarmserver_PIR_Switch/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "motioneye-shows-a-strange-video-image-after-some-running-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#motioneye-shows-a-strange-video-image-after-some-running-time",
        "aria-label": "motioneye shows a strange video image after some running time permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MotionEye shows a strange video image after some running time`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: The problem shows up in the fact that at first all pictures are displayed perfectly, but after a while the pictures of the IN5907 as well as the 6014 become "psychedelic": it is a multitude of colours that makes it impossible to recognise the picture.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/41159b5388fcc7202a438f7f7be4b5f8/29114/RTSP_over_UDP_Keyframe_Drops.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABcUlEQVQoz11S0W6DMAzkV6e99WHatFIgxHacFLrup2+yk1C0B2TkOHfnyw3MAmaGiCCl5LV+tWdn1i+lQFW9R0Te7/esEhNYGIMNpyRQr/XfqhF1kk5kFw3UAYgg8hLggMwYbMAuOPvp8tfXiNs0Q7M2Am6AbeYgZhfRgYecsx+E9bQGC5YlHIq6BU58gKgDsUSwEDgFfI83DEkr2zQHrL6GecRYlhWlkeWS3cMkL0VmSdke0CzeN0Fv7x/mocBAbTUWcUUxRoQ1oqu3Nfd98zlTU1cm5LJBlLGGBCIFKWPoxvu6wg5qZrsKqr4Y2XYvUF9TT2lgqBSESCAKEB0xiLOl9vQV9BwdU2kr56xQeSWhx4c4It8zSGZQmqrCqlKOaJwfJ2t2G/QULeHkZDYTKeL5/MW+P8DhpwG2zGmLzzns5p9HpRH3mNVzBlOuXwpQIQz/Ey+nTJlnpvA6XhHX9djicrlgmqcK7BZFRBkxxU/8AfVdIfsltavNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41159b5388fcc7202a438f7f7be4b5f8/e4706/RTSP_over_UDP_Keyframe_Drops.avif 230w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/d1af7/RTSP_over_UDP_Keyframe_Drops.avif 460w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/7f308/RTSP_over_UDP_Keyframe_Drops.avif 920w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/e1c99/RTSP_over_UDP_Keyframe_Drops.avif 1380w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/76ea5/RTSP_over_UDP_Keyframe_Drops.avif 1840w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/f696c/RTSP_over_UDP_Keyframe_Drops.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/41159b5388fcc7202a438f7f7be4b5f8/a0b58/RTSP_over_UDP_Keyframe_Drops.webp 230w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/bc10c/RTSP_over_UDP_Keyframe_Drops.webp 460w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/966d8/RTSP_over_UDP_Keyframe_Drops.webp 920w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/445df/RTSP_over_UDP_Keyframe_Drops.webp 1380w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/78de1/RTSP_over_UDP_Keyframe_Drops.webp 1840w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/882b9/RTSP_over_UDP_Keyframe_Drops.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/41159b5388fcc7202a438f7f7be4b5f8/81c8e/RTSP_over_UDP_Keyframe_Drops.png 230w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/08a84/RTSP_over_UDP_Keyframe_Drops.png 460w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/c0255/RTSP_over_UDP_Keyframe_Drops.png 920w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/b1001/RTSP_over_UDP_Keyframe_Drops.png 1380w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/161ec/RTSP_over_UDP_Keyframe_Drops.png 1840w", "/en/static/41159b5388fcc7202a438f7f7be4b5f8/29114/RTSP_over_UDP_Keyframe_Drops.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/41159b5388fcc7202a438f7f7be4b5f8/c0255/RTSP_over_UDP_Keyframe_Drops.png",
              "alt": "MotionEye dropped keyframes",
              "title": "MotionEye dropped keyframes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`A`}</strong>{`: The problem with the video from the HD cameras in MotionEye is related to the transmission of the RTSP stream. Such image distortions or colour distortions are caused by the loss of keyframes, which are required by the H264 codec to correctly display a compressed sequence of frames.`}</p>
    <p>{`The RTSP protocol provides that a client can establish the connection either via UDP or TCP. The common libraries for RTSP clients use UDP transmission as the default (if available). This can cause the problem mentioned above, as it is a protocol without any error correction or retransmission of lost network packets.`}</p>
    <p>{`The HD cameras support both RTSP via UDP and TCP, the FHD cameras only TCP and no UDP. Which protocol is used for RTSP depends on the client (i.e. the software). In the VLC player, the picture should also show this behaviour after a certain time, as you also have to configure the settings there to force RTSP via TCP.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "737px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/d125e/RTSP_over_UDP_Keyframe_Drops_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACZklEQVQoz12S20/TcBzF9wzbWLuNbb1tXdttjLFL23WDXVp3QQhIRIgSlagJJr7IJT4ZHyTGiP/1x/QHYuLDyfftk/M95ySysoz8v3J55Ny6kJTNs9Fs0rdNtus2tYaDrmuomo5ulDHKZXEVVcOyHRLpdBpJlslms0KSJAulUmlS6TVWV1dxag6WruHXLBoNB0VVUFQVTb+HaoZBSVGpWjYJSco8wmKtFwoUSgr5QpH1YolSSaHd3qJRc+i1Nmhu1CiXDUzbwTCrqJqGquv3wKpFQspkyOX+upOwLIug38fzPLrdLrPZnMVyyXK5i9/v0+n26PZc3OEEp9URbuP3i4qKGQNTySSxy3w+L/KzbZvRaEQYhix2n7J3dMLes2Pme4cMJ09wByPc8RzPdXEcB+XBYaGoPABTKWLFsNhhtVolCAKiKGQURgyW+7SG40c1+kOafkCr18GOgaomMowdVszqP2AmkxHXNE1c12W+mHM4W/Dp5D0fX5zz4eg17w7PuDg+53T3JUfBlJpli0UUiiWR+T0wmSRW3PbKyorIcH9/n8VywdvD53z//IPbmztub37x7fInt19+8/Xqjus3l3jtNna9hmVb4rM4goQkthaXIrO2lqFer3NwcCDKCGczxtGEcThmEo6ExtMR03CEF3i4vs80CgmjCN9zabc2SWTkLJKcFaVkczlRiuv38QdDotmcyTTCH+zQPrqg9+qa4OyKrdMrepMlNdvCKFcwTYtKucJms0kinUqy9jDuXC4vwPH24rB1wxAD7nS7eJ0+g+0pwWzBhr+D3WhSqVTQNP1+NkVF7PAP11lF6mp8KiMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/e4706/RTSP_over_UDP_Keyframe_Drops_02.avif 230w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/d1af7/RTSP_over_UDP_Keyframe_Drops_02.avif 460w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/27f85/RTSP_over_UDP_Keyframe_Drops_02.avif 737w"],
              "sizes": "(max-width: 737px) 100vw, 737px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/a0b58/RTSP_over_UDP_Keyframe_Drops_02.webp 230w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/bc10c/RTSP_over_UDP_Keyframe_Drops_02.webp 460w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/a99e1/RTSP_over_UDP_Keyframe_Drops_02.webp 737w"],
              "sizes": "(max-width: 737px) 100vw, 737px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/81c8e/RTSP_over_UDP_Keyframe_Drops_02.png 230w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/08a84/RTSP_over_UDP_Keyframe_Drops_02.png 460w", "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/d125e/RTSP_over_UDP_Keyframe_Drops_02.png 737w"],
              "sizes": "(max-width: 737px) 100vw, 737px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f5dec321f5062eedb18fa44c2a0f0ca7/d125e/RTSP_over_UDP_Keyframe_Drops_02.png",
              "alt": "MotionEye dropped keyframes",
              "title": "MotionEye dropped keyframes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In MotionEye, when adding the camera via the RTSP stream - for example:`}</p>
    <ul>
      <li parentName="ul">{`rtsp://192.168.2.116/11`}</li>
      <li parentName="ul">{`rtsp://192.168.2.116/12`}</li>
      <li parentName="ul">{`rtsp://192.168.2.116/13`}</li>
    </ul>
    <p><em parentName="p">{`It is recommended to use the middle stream `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`/12`}</code></em>{`.`}</p>
    <p>{`Add and use the `}<strong parentName="p">{`TCP`}</strong>{` camera preset.`}</p>
    <p>{`Another possibility is to set this in motion via the configuration file with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp_uses_tcp`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`netcam_use_tcp`}</code>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://motion-project.github.io/motion_config.html#netcam_use_tcp"
        }}>{`rtsp_uses_tcp=on / netcam_use_tcp=on`}</a></li>
    </ul>
    <p>{`However, if motioneye was installed via an image (motioneyeos), the partition is "read-only" and changing motioneye.conf is not possible. To change the file, you must remount the two partitions `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`boot`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`root`}</code>{` as writable:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mount`}</span>{` -o remount,rw /
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mount`}</span>{` -o remount,rw /boot`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/ccrisan/motioneyeos/wiki/FAQ#why-do-i-get-read-only-file-system-when-trying-to-edit-a-file"
        }}>{`Why do I get a read-only file system when I try to edit a file?`}</a></li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      